import React, { useState, useRef, useEffect } from 'react';
import { FaGithub, FaLinkedin, FaHome, FaInfoCircle, FaPen, FaEnvelope, FaBars, FaTimes } from 'react-icons/fa';

export const Header: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const githubLink = "https://github.com/TheDMNMachine";
  const linkedinLink = "https://www.linkedin.com/in/damian-jarominek-78a83215b/";

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        closeMenu();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const navLinks = [
    { href: "/", icon: <FaHome size={20} className="mr-2" />, label: "Home" },
    { href: "#about", icon: <FaInfoCircle size={20} className="mr-2" />, label: "O mnie" },
    { href: "/", icon: <FaPen size={20} className="mr-2" />, label: "Blog" },
    { href: "#contact", icon: <FaEnvelope size={20} className="mr-2" />, label: "Kontakt" }
  ];

  return (
    <header className="bg-navy text-gold shadow-md relative">
      <div className="flex justify-between items-center p-4">
        {/* Full navigation for desktop */}
        <nav className="hidden md:flex space-x-6">
          {navLinks.map((link, index) => (
            <a key={index} href={link.href} className="flex items-center hover:text-gray-300">
              {link.icon}
              {link.label}
            </a>
          ))}
        </nav>

        {/* Social Icons and Subscribe Button */}
        <div className="hidden md:flex space-x-4">
          <a href={githubLink} target="_blank" rel="noopener noreferrer" className="hover:text-gray-300">
            <FaGithub size={24} />
          </a>
          <a href={linkedinLink} target="_blank" rel="noopener noreferrer" className="hover:text-gray-300">
            <FaLinkedin size={24} />
          </a>
          <button className="bg-gold text-navy py-2 px-4 rounded hover:bg-white transition-colors">
            Subskrybuj
          </button>
        </div>

        {/* Hamburger Icon for Mobile */}
        <div className="md:hidden">
          <button
            ref={buttonRef}
            id="menu-button"
            onClick={toggleMenu}
            className="text-gold focus:outline-none"
          >
            {isOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
          </button>
        </div>
      </div>

      {/* Sidebar Mobile Menu */}
      <div
        ref={menuRef}
        className={`fixed top-0 left-0 h-full bg-navy text-gold transition-transform duration-300 transform ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        } w-64 z-50 md:hidden`}
      >
        <nav className="flex flex-col space-y-4 p-4">
          {navLinks.map((link, index) => (
            <a
              key={index}
              href={link.href}
              className="flex items-center text-lg hover:text-gray-300 transition-colors"
              onClick={closeMenu}  // Close menu on link click
            >
              {link.icon}
              {link.label}
            </a>
          ))}
        </nav>

        <div className="mt-8 flex space-x-4 p-4">
          <a
            href={githubLink}
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-gray-300 transition-colors"
          >
            <FaGithub size={30} />
          </a>
          <a
            href={linkedinLink}
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-gray-300 transition-colors"
          >
            <FaLinkedin size={30} />
          </a>
        </div>
      </div>
    </header>
  );
};
