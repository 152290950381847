import React from 'react';
import logo from '../assets/logo.svg';

export const LogoTitleSection: React.FC = () => {
  return (
    <section className="flex flex-col sm:flex-row items-center py-10 bg-white text-navy shadow-md">
      <div
        className="flex justify-center bg-navy mb-4 sm:mb-0 sm:mr-4"
        style={{ height: '150px', width: '150px' }}
      >
        <img
          src={logo}
          alt="Logo"
          className="object-contain h-full w-full"
          style={{ filter: 'invert(1)' }}
        />
      </div>
      <div className="flex-1 text-center sm:text-left">
        <h1 className="text-4xl sm:text-5xl font-bold mb-2">DJarominek.DEV</h1>
        <p className="text-lg sm:text-xl text-gray-700">Blog o programowaniu, mechatronice, błędach i sukcesach.</p>
      </div>
    </section>
  );
};
