import React from 'react';
import {FaGithub, FaLinkedin } from 'react-icons/fa';

export const Footer: React.FC = () => {
    return (
        <footer className="bg-navy text-gold py-6">
            <div className="container mx-auto flex flex-col md:flex-row justify-between items-center">
                <div className="text-center md:text-left mb-4 md:mb-0">
                    <p>&copy; DJarominek.DEV 2024. All rights reserved.</p>
                </div>
                <div className="flex space-x-6">
                    <a href="https://github.com/TheDMNMachine" target="_blank" rel="noopener noreferrer">
                        <FaGithub size={24}/>
                    </a>
                    <a href="https://www.linkedin.com/in/damian-jarominek-78a83215b/" target="_blank" rel="noopener noreferrer">
                        <FaLinkedin size={24}/>
                    </a>
                </div>
            </div>
        </footer>
    );
};
