import React from 'react';
import foto from '../assets/foto.png'

export const Sidebar: React.FC = () => {
    const latestPosts = [
        "Soon..."
    ];

    return (
        <aside className="bg-white p-6 rounded-lg shadow-md">
            <div className="text-center mb-6">
                <img
                    src={foto}
                    alt="Your Name"
                    className="w-24 h-24 rounded-full mx-auto mb-4 bg-gradient-to-r from-blue-200 to-blue-800"
                />
                <h2 className="text-2xl font-bold">Damian "DMNMachine" Jarominek</h2>
                <p className="text-gray-600">Cześć jestem inżynierem, który chce podzielić się tym co robi.
                    Niekoniecznie wiedzą, bo pewnie będą tu elementy nauki od innych. Zajmuje się programowaniem i
                    mechatroniką, tutaj chce publikować moje projekty i przemyślenia.</p>
            </div>

            <div className="mb-6">
                <h3 className="text-xl font-semibold mb-4">Ostatnie posty</h3>
                <ul className="list-disc list-inside">
                    {latestPosts.map((post, index) => (
                        <li key={index} className="text-gray-700 hover:underline">
                            {post}
                        </li>
                    ))}
                </ul>
            </div>

            <div>
                <h3 className="text-xl font-semibold mb-4">Informacje</h3>
                <p className="text-gray-600">Co jest celem bloga? Głównie skończyć go i reguralnie publikować, bo
                    zbieram się do tego od dawna. Obecnie chciałbym pokazać tutaj coś o druku 3D, o programowaniu, o
                    automatyzacji, o AI,o budowaniu urządzeń i starać się to pokazać jak najprościej, tak, że nie wiedząc za
                    dużo uda się zreplikować to co zrobiłem.</p>
            </div>
        </aside>
    );
};
